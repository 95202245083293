<template>
  <div class="readme-article">
    <h1 id="高级功能">
      三、高级功能
    </h1>
    <h2 id="我的战绩">1.我的战绩</h2>
    <p>查看我的KPI，业绩数据分析</p>
    <p>
      <img src="@/assets/img/recruit/3-1.png">
    </p>
    <h2 id="将学员导入线索">2.将学员导入线索</h2>
    <p>搜索学员，可将学员导入到招生线索中</p>
    <p>
      <img src="@/assets/img/recruit/3-2.png">
    </p>
    <h2 id="线索升级">3.线索升级</h2>
    <p>支持批量升级招生线索，提升或降低线索的年级</p>
    <p>
      <img src="@/assets/img/recruit/3-3.png">
    </p>
    <h2 id="导入线索资料">4.导入线索资料</h2>
    <p>通过表格文件一键导入招生线索</p>
    <p>
      <img src="@/assets/img/recruit/3-4.png">
    </p>
    <h2 id="操作日志查询">5.操作日志查询</h2>
    <p>查询系统操作日志，追踪记录系统用户的每一步操作</p>
    <p>
      <img src="@/assets/img/recruit/3-5.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit3-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>